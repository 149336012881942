import React from 'react';
import { useState, useEffect } from "react";
import  Admin  from "./admin";
//import { useNavigate } from "react-router-dom";


import JsonData from "../../data/data.json";
//import "../App.css";

const AdminDash = () =>{
  // let navigate = useNavigate();
  // function Salir() {
  //   console.log("dentro del handle go()");
  //   //historyObj.push("../AdminDash");
  //   navigate('../Usuarios2');

  // }

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <br/>
      <div></div>
      {/* <div><button type="button" onClick={Salir}> Salir </button></div> */}
      <div>
      <Admin data={landingPageData.Admin} />
      </div>
    </div>
  );
}
export default AdminDash;