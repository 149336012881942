
import React, { useState } from "react";
import Desaferrarse from './Modals/Desaferrarse/ContainerReg';
import Abundancia from './Modals/Abundancia/ContainerReg';
import Saber from './Modals/Saber/ContainerReg';

export const Testimonials = (props) => {

  const [btn] = useState([]);

  const Modal = () => {
    //console.log("ingresando al modal!")
    //console.log(Modal.id)
    if (btn === "Abundancia") {
      //console.log("Entrando por Abundancia")
      const Abundancia = (event) => {
        //console.log("Abundancia!!!!")
        event.preventDefault(event);
      };
    };
    if (btn === "Desaferrarse") {
      //console.log("Entrando por Desaferrarse")
      const Desaferrarse = (event) => {
        //console.log("Desaferrarse!!!!")
        event.preventDefault(event);
      };
    };
    if (btn === "Saber") {
      //console.log("Entrando por SABER")
      const Saber = (event) => {
        //console.log("Abundancia!!!!")
        event.preventDefault(event);
      };
    };
  }

  return (
    <div id='testimonials' className='text-center'>
      <br></br><br></br><br></br><br></br>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Mensajes del Padre Walter Medina</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-4' >
                <div class="testimonialscontenedor">
                  <div className='testimonial'>
                    <div className='testimonial-image'>
                      {' '}
                      <img src={d.img} alt='' />{' '}
                    </div>
                    <div className='testimonial-content'>
                      <div className='testimonial-meta'> - {d.name} </div>
                      <p className="text-justificado">{d.text}</p>
                      <p hidden={(d.enlace === "Saber") ? false : true} className="text-centrado"><Saber triggerRegistros="Leer mas..." onSubmit={Modal} id={d.enlace} /></p>
                      <p hidden={(d.enlace === "Desaferrarse") ? false : true} className="text-centrado"><Desaferrarse triggerRegistros="Leer mas..." onSubmit={Modal} id={d.enlace} /></p>
                      <p hidden={(d.enlace === "Abundancia") ? false : true} className="text-centrado"><Abundancia triggerRegistros="Leer mas..." onSubmit={Modal} id={d.enlace} /></p>
                    </div>
                  </div>
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
