// Importa useState y useEffect
import React, { useState, useEffect } from "react";

const EditForm = ({ data, table, onClose, onUpdate, isAdding , onCancel}) => {
  const [fieldData, setFieldData] = useState(isAdding ? {} : { ...data });
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Nueva función para manejar la edición de campos de texto largos
  const handleTextareaChange = (e) => {
    const { name, value } = e.target;
    setFieldData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = () => {
    // Resto del código de handleSave...
   
            const fieldsWithoutBlanks = Object.keys(fieldData).reduce((result, key) => {
              if (fieldData[key] !== "") {
                result[key] = fieldData[key];
              }
              return result;
            }, {});
          
            if (isSaving) {
              return; // Evita que el formulario se envíe si ya se está guardando
            }
          
            if (Object.keys(fieldsWithoutBlanks).length === 0) {
              console.log("No se pueden guardar registros en blanco. Edit form");
              return;
            }
          
          setIsSaving(true);
      
          const filteredFieldData = Object.keys(fieldData).reduce((result, key) => {
            if (fieldData[key] !== "") {
              result[key] = fieldData[key];
            }
            return result;
          }, {});
      
          fetch(
            `https://www.padrewaltermedina.com.ar/php/admintabla${table}.php?action=${isAdding ? "add" : "edit"}${table}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(filteredFieldData),
            }
          )
            .then((response) => response.json())
            .then((result) => {
              if (result.success) {
                if (isAdding) {
                  onUpdate(filteredFieldData);
                }
                onClose();
              } else {
                onClose();
              }
            })
            .catch((error) =>
              console.error(`Error ${isAdding ? "adding" : "updating"} data:`, error)
            );
        
    // Asegúrate de que estás enviando los datos con los campos de texto largos correctamente.
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel(); // Llama a la función de cancelación proporcionada por el componente padre
    }
    onClose();
  };

  useEffect(() => {
    if (isAdding) {
      const emptyData = {};
      Object.keys(data).forEach((key) => {
        emptyData[key] = "";
      });
      setFieldData(emptyData);
    } else {
      setFieldData({ ...data });
    }
  }, [data, isAdding]);

  return (
    <div>
      <div style={{ width: "60%", margin: " 0 auto" }}>
        <div>
          <h3>{isAdding ? "Agregar Registro" : "Editar Registro"}</h3>
          <h6>
            {isAdding
              ? "NO INGRESAR valores en campo Id, fechayhora"
              : "NO MODIFICAR valores en campo Id, fecha y hora"}
          </h6>

          <form>
            {Object.keys(fieldData).map((key) => (
              <div key={key}>
                <label htmlFor={key}>{key}</label>
                {key === "post" || key === "post1" ? ( // Verifica si el campo es 'post' o 'post1'
                  <textarea
                    id={key}
                    name={key}
                    value={fieldData[key] || ""}
                    onChange={handleTextareaChange}
                    rows={4} // Puedes ajustar la cantidad de filas según tus necesidades
                  />
                ) : (
                  <input
                    type="text"
                    id={key}
                    name={key}
                    value={fieldData[key] || ""}
                    onChange={handleInputChange}
                  />
                )}
              </div>
            ))}
          </form>
        </div>
        <div>
          <button onClick={handleSave} disabled={isSaving}>
            {isAdding ? "Guardar" : "Guardar Cambios"}
          </button>
          <button onClick={handleCancel}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default EditForm;

// import React, { useState, useEffect } from "react";

// const EditForm = ({ data, table, onClose, onUpdate, isAdding }) => {
//   const [fieldData, setFieldData] = useState(isAdding ? {} : { ...data });
//   const [isSaving, setIsSaving] = useState(false);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFieldData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleSave = () => {
//       const fieldsWithoutBlanks = Object.keys(fieldData).reduce((result, key) => {
//         if (fieldData[key] !== "") {
//           result[key] = fieldData[key];
//         }
//         return result;
//       }, {});
    
//       if (isSaving) {
//         return; // Evita que el formulario se envíe si ya se está guardando
//       }
    
//       if (Object.keys(fieldsWithoutBlanks).length === 0) {
//         console.log("No se pueden guardar registros en blanco. Edit form");
//         return;
//       }
    
//     setIsSaving(true);

//     const filteredFieldData = Object.keys(fieldData).reduce((result, key) => {
//       if (fieldData[key] !== "") {
//         result[key] = fieldData[key];
//       }
//       return result;
//     }, {});

//     fetch(
//       `https://www.padrewaltermedina.com.ar/php/admintabla${table}.php?action=${isAdding ? "add" : "edit"}${table}`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(filteredFieldData),
//       }
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.success) {
//           if (isAdding) {
//             onUpdate(filteredFieldData);
//           }
//           onClose();
//         } else {
//           onClose();
//         }
//       })
//       .catch((error) =>
//         console.error(`Error ${isAdding ? "adding" : "updating"} data:`, error)
//       );
//   };

//   useEffect(() => {
//     if (isAdding) {
//       const emptyData = {};
//       Object.keys(data).forEach((key) => {
//         emptyData[key] = "";
//       });
//       setFieldData(emptyData);
//     } else {
//       setFieldData({ ...data });
//     }
//   }, [data, isAdding]);

//   return (
//     <div>
//       <div style={{ width: "60%", margin: " 0 auto" }}>
//         <div>
//           <h3>{isAdding ? "Agregar Registro" : "Editar Registro"}</h3>
//           <h6>{isAdding ? "NO INGRESAR valores en campo Id, fechayhora" : "NO MODIFICAR valores en campo Id, fecha y hora"}</h6>

//           <form>
//             {Object.keys(fieldData).map((key) => (
//               <div key={key}>
//                 <label htmlFor={key}>{key}</label>
//                 <input
//                   type="text"
//                   id={key}
//                   name={key}
//                   value={fieldData[key] || ""}
//                   onChange={handleInputChange}
//                 />
//               </div>
//             ))}
//           </form>
//         </div>
//         <div>
//           <button onClick={handleSave} disabled={isSaving}>
//             {isAdding ? "Guardar" : "Guardar Cambios"}
//           </button>
//           <button onClick={onClose}>Cancelar</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EditForm;
