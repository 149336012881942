import React from 'react';
import "../form.css";
const triggerRegistros = ({ triggerRegistros, buttonRef, showModal }) => {
  return (
    <button
    className="btnsAdmin"
      ref={buttonRef}
      onClick={showModal}
    >
      {triggerRegistros}
    </button>
  );
};
export default triggerRegistros;