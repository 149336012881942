import React from "react";
import "../form.css";

export const Form = ({ onSubmit }) => {

  return (


    <form onSubmit={onSubmit}>
      <h3>El saber, sin el sabor, no es saber</h3>
            <div className="form-group" >
        <p>El saber, sin el sabor, no es saber. Porque solo conocemos de verdad, aquello que saboreamos.
Saber muchas cosas lindas y maravillosas sobre Dios, no es suficiente. Las ideas y pensamientos sobre el amor de Dios pueden ser muy valiosos, pero necesitamos saborearlos, vivirlos, antes de poder decir que los conocemos.
Es como con la receta de hacer pan, te puede servir incluso para enseñarlo, pero la experiencia de masticar un trozo de pan caliente es un conocimiento distinto que no puede ser reemplazado por el intelectual. El amor de Dios es ese trozo de pan desconocido del que a veces hemos tenido una receta pero quizás nunca hemos probado todavía.
Si sabemos que Dios nos ama y todavía no somos felices, es probable que sea solo un conocimiento intelectual. Y es tan triste, tan contrario a lo que Dios está buscando en nuestra vida. Hace tanto tiempo que Él no deja de buscarnos para hacernos descubrir que está contento con nosotros, que nos ve y se maravilla de cada uno. No por lo que hacemos o no hacemos, sino por quienes somos. A veces podemos sentir vergüenza de nosotros mismos, pero él no se avergüenza de lo que ha creado. No pone condiciones para amarnos, cuidarnos, darnos la seguridad y la certeza de que todo lo podemos en Él.
Vivir en su Amor, es un regalo que Él nos quiere dar, pero esto implica una conversión a la que tal vez no estamos dispuestos. Significa animarnos a ser pequeños y confiar. Para vivir en los brazos de Dios hay que animarse día a día a ser pequeños, pobres, sin fuerzas. Y esto es lo difícil. Porque nuestro peor enemigo, el orgullo, busca todo lo contrario. Y el orgullo hace tiempo nos domina y no nos deja conocer el amor de Dios, porque rechaza ser pequeños en sus brazos.
No se obtiene nuevos resultados haciendo las mismas acciones, sino que los resultados nuevos vienen de nuevas acciones. Por eso lo que nos planteamos son acciones más que ideas. Acciones en las que demostramos nuestra disposición a conocer el amor de Dios como quien come un pedazo de pan caliente..</p>
<p><br></br><br></br></p>
<p><br></br><br></br></p>
      </div>
    </form>
  );
};
export default Form;
