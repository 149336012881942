import React from "react";
import "../form.css";

export const Form = ({ onSubmit }) => {

  return (


    <form onSubmit={onSubmit}>
      <h3>Desaferrarse</h3>
            <div className="form-group">
        <p>La vida es muy corta para decir que somos dueños de algo. La realidad terminará mostrando que nada podemos poseer, pero todo lo podemos recibir. Aferrarnos a las cosas, sean materiales o incluso las espirituales, nos hace olvidar para que hemos sido creados. Si un pájaro no vuela y solo quiere caminar en la tierra, podrá hacerlo, pero vivirá una vida sin su satisfacción más profunda. Se enredará con las cosas de la tierra cuando en realidad fue creado para el cielo. Vivirá triste, con miedo y preocupado. Por qué no puede experimentar su propia realidad del amor que lo ha creado para volar. Tratará de aferrarse a lo que intenta, competirá con otros que le quieren quitar lo suyo. A veces ganará, a veces perderá. Pero siempre estará ansioso y a hasta angustiado. Por qué aun conquistando lo que quiere, siempre tendrá miedo a perderlo.
Como un niño que ha crecido, o un pájaro que ha olvidado volar nosotros ahora queremos animarnos a ser nosotros. Volver a nuestra casa, donde respiramos profundo. Volver a nuestra verdad de ser creados para volar. Dios no nos creó para que vivamos con miedo, sino para que nos animemos a dejarnos amar.
Somos como ese pájaro que en algún momento de su vida mirará al cielo y tal vez se anime a volver a ser él. Sus alas débiles por la falta de práctica, al comienzo le resultarán amargas. Sentirá que no puede. Pero si se deja salvar, solo por este día estará bien. Si continúa agitando sus alas y confía en la verdad de que es hijo, verá que él también puede todo lo que puede su Padre. Pondrá como primero, lo primero, y renunciará a todo lo demás con alegría, por haber encontrado un tesoro. Intentando volar se dará cuenta de que si busca la abundancia del cielo, todo lo demás se le dará por añadidura. Será pobre en paz, y cuando menos lo quiera, lo tendrá todo.</p>
<p><br></br><br></br></p>
<p><br></br><br></br></p>
      </div>
    </form>
  );
};
export default Form;
