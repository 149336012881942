import React from "react";
import "../form.css";

export const Form = ({ onSubmit }) => {

  return (


    <form onSubmit={onSubmit}>
      <h3>POR QUE TE AFERRAS A LO QUE TE HACE DAÑO</h3>
      <div className="form-group">
        <p>Introduccion
          "La abuelita que cuida sus ovejas en el cerro a 3000 msnm, sola, entre soles, fríos, desiertos, abrazada por el azul intenso del firmamento... puede tener sus disgustos. Pero muchas veces, mientras cuida las ovejas en silencio, simplemente está ahí. Tiene, como nosotros, sus preocupaciones, miedos y ansiedades. Pero no se enreda en ellos. No se aferra mentalmente tratando de resolverlos. Reza su rosario debajo de ese azulísimo cielo y espontáneamente casi sin darse cuenta, elige soltar y confiar. Deja de estar afectada por lo que le preocupaba, lo deja ir. Lo suelta porque confía. Está simplemente en ese “aquí y ahora” de su cuidar sus ovejas, presente frente a la inmensidad que abraza sus ovejas. Tal vez ella no sepa que es contemplativa, pero sí se sabe en la presencia de Dios.
          Por compartir la vida de personas que viven en el silencio de la puna salteña, por el trabajo espiritual en comunidades de adictos y por la amistad con movimientos de oración contemplativa, es que se originó este libro. Se trata de compartir un camino que puede llevarnos a la paz. Es simplemente eso, un camino para ser caminado. No buscamos enseñar, sino proponer que aprendamos[…]”</p>
        <p>No vemos las cosas como son
          "Qué fácil es responsabilizar de nuestro estado emocional a lo que esté pasando, sin darnos cuenta de que también, en parte, no vemos las cosas como son. Creemos que son los acontecimientos, situaciones o personas las que nos hacen sentir de tal o cual manera. Que frecuente es oír: “Estoy mal por lo que el otro dijo, hizo o no hizo”, “Estoy contento porque pasó esto o lo otro”. Cuando en realidad también es lo que uno piensa respecto a algo lo que nos hace sentirnos bien o mal.
          Un niño se ataja como si fueran a pegarle, por ejemplo, cuando la maestra acerca la mano a su hombro. La acción de la maestra la ve no como es, sino de acuerdo con lo que él experimentó en su casa cuando le pegaron. Pero no sería raro que incluso al ser adulto lleve consigo esa experiencia que le haga juzgar a cualquier autoridad como abusiva, ya que fueron abusivos con él cuando niño. Así el simple hecho de que la policía le pida al conducir sus credenciales, lo podría poner tenso como si fuera a sufrir un maltrato. El maltrato que existió de[…]”
          “Cuando soltamos nuestros juicios, vamos descubriendo que nuestro juez no era imparcial. En realidad, está enceguecido por su propia historia y no se daba cuenta. “Guías ciegos que guían a otros ciegos...” decía Jesús, “y si un ciego guía a otro, ambos (los dos) caerán en un pozo” (Mt 15, 14). En nuestra ceguera creemos que podemos decirle al otro como debe comportarse. En nuestra ceguera caminamos sin saber a dónde. Los pozos en los que caemos nos enseñan que no estamos mirando la realidad. La realidad es el amor de Dios. Pero nuestro juez, no ve la realidad sino sus miedos. En nuestra historia hubo muchos juicios, experiencias, aprendizajes que hacen que tengamos un juez que nos miente y debemos desprendernos de sus juicios. ¿Cómo sabemos que este juez nos miente? Si no tenemos paz y alegría, podemos estar seguros de que no vemos las cosas como son, no vemos que nada es más fuerte que el amor de Dios. Nuestros juicios son parciales y producen emociones también parciales, no ven toda la realidad como es. El que ve la realidad, tiene paz y alegría.
          Desde niños aprendimos a nombrar lo que estaba a nuestro alrededor. Nombrar, es[…]”</p>
        <p>Ser salvados de uno mism
          "Jesús nos vino a mostrar una nueva manera de ver. Él es el Maestro que enseña la buena noticia. La buena noticia también es un paradigma, una manera de vernos y ver el mundo. En ella descubrimos el rostro de Dios, que es Padre amoroso y Providente. En ella nos sabemos sus hijos, y vivimos el nuevo mandamiento del amor. En ella las bienaventuranzas nos hacen estar en paz con nuestra pobreza, experimentamos el perdón y que Dios se ocupa de nosotros como un pastor. Confiamos y soltamos nuestras inquietudes de tormenta, porque sabemos que Él está en nuestra barca y nada malo pasará. Si no vivimos en esta verdad, es porque nos atrapa, no el paradigma de la buena noticia, sino el de nuestra propia mirada de tiniebla. Buscamos salvación de nuestros problemas, y no nos damos cuenta de que el problema somos nosotros y que Dios busca salvarnos también de nosotros mismos.
          Creer que el problema está afuera de nosotros cuando en realidad somos nosotros, no nos deja ver el problema.
          Queremos solucionar algo, hasta podemos tener una lista de las cosas que deberían cambiar para que estemos bien, pero nos cuesta[…]”</p>
        <p>Necesidad de amor, seguridad y control
          "En el capítulo anterior vimos que cuando éramos niños, muy probablemente, tuvimos necesidades que no fueron satisfechas. Algunas ni las recordamos, pero tal vez hoy siguen allí, buscando saciarse. En este capítulo nos referiremos a ellas, de un modo más específico. Para que podamos descubrir que es lo que realmente nos inquieta, detrás de muchas emociones que no son la verdadera causa de nuestro malestar.
          Descubrir cómo estas necesidades nos movilizan más de lo que pensamos, nos ayuda a no dejar que nos controlen. Porque a veces reaccionamos de manera desmedida a muchas situaciones. Porque no nos damos cuenta de que tenemos necesidades ocultas que nos dominan. Así, por ejemplo, hay en nosotros una necesidad de control que es normal y viene de un instinto de supervivencia.
          Una mamá se enojaba mucho cuando su hijo no le contestaba un mensaje de texto. Y sin tratar de opinar sobre el tema de si correspondía o no contestar los mensajes, este caso nos sirve para ejemplificar que lo que en realidad más le molestaba a esta madre, era que sentía que no tenía control sobre su hijo. No se trataba tanto de si era irresponsable[…]”</p>
        <p>Aceptar nuestra familia
          "Generalmente nuestros vínculos son la fuente de las mayores alegrías y dolores. Es en nuestras relaciones donde más nos realizamos como personas. Cuando tenemos un problema en nuestros vínculos tendemos a culpar al otro, pero en la oración contemplativa veremos que cuando solucionamos lo de adentro, que está en nosotros, lo de afuera también se soluciona. Y solucionar no es fruto de nuestro esfuerzo sino de la gracia de aprender a aceptar y soltar.
          Para ser plenos lo más importante no es lo que hacemos, donde estamos, que tenemos... etc. sino con quienes estamos. La felicidad es aprender a entrar en comunión. Aprender a estar con otros, es lo que nos realiza porque solo así podemos vivir la expresión de nuestro ser más esencial, que es recibir y dar amor sin esperar nada a cambio. Este estar con otros, no siempre significa lo físico del que tenemos a nuestro lado. Es más, a veces estamos más en comunión con personas que la distancia parecería impedirlo. Jesús decía de su comunión con el Padre:
          “Yo estoy en el Padre, y el Padre en mí” (Jn 14,11)
          Si ser relacional, quiere decir que nuestra identidad siempre[…]”</p>
        <p>La Fe y las creencias
          "Jesús lo decía con frecuencia: “tu fe te ha salvado” (Cf. Lc 7,5; Mc 5,34; Lc 17,1p); o también: “que suceda como has creído” (Mt 8,13). Es notorio como en la mayoría de los milagros, él no hace solo referencia al obrar de Dios, sino sobre todo a la fe del hombre. El evangelio está lleno de escenas donde se denota que la intervención divina sobrenatural de un milagro ocurre cuando alguien tiene cree. Esto no es absoluto, no decimos que el obrar de Dios dependa del creer de sus hijos, ya que él, obra siempre, creamos o no. Y además podemos agregar que el creer ya es un don de Dios:
          “…puestos los ojos en Jesús, el autor y consumador de la fe” (Heb 12,2)
          La fe es un don, pero eso no significa que Dios no nos de la libertad de aceptar o rechazar este don. Podemos elegir creer y qué creer. Y en esta libertad se nos invita dejarnos iluminar por una fe que transforma nuestras creencias para vivir en la verdad.
          Todo es obra de Dios, pero él ha querido hacernos parte de su obrar. Así, vemos el lugar que[…]”</p>
        <p>Experimentar como Dios actúa
          "En la oración contemplativa hacemos silencio para conectarnos con lo que experimentamos. Esta experiencia no es pensar. Sino que es dejar que la realidad misma se manifieste. La realidad interior lo que sentimos, la realidad exterior de lo que acontece y la realidad suprema de Dios que siempre nos busca y nos espera. Dios es amor y esa es la realidad profunda a la que nos disponemos con la humildad de la oración.
          Los judíos, basaron su fe en un hecho salvífico: eran esclavos de los egipcios y Dios abrió el mar para que ellos pasen y así los salvó. La fe de este pueblo fue fruto de la experiencia de hechos concretos de salvación, liberación y amor providente. Dios era para ellos aquel que había irrumpido en su historia concreta y los había liberado. Podían recodarlo, podían pensarlo, podían escribirlo, pero la Pascua Judía fue ante todo algo que se vivía. Esta experiencia se trasmitió de generación en generación. Si un niño judío le preguntaba a su mamá quién era Dios, no le respondían solo con ideas, sino que le hablaba de hechos, de experiencias, le contaba Dios es el que hizo[…]”</p>
      </div>
    </form>
  );
};
export default Form;
