import React from "react";
import youtubeLogo from "../data/img/LogoYouTube.png";

export const Cursos = (props) => {
  
  return (
    <div id="cursos" className="text-center">
      <div className="cursosback">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="container">
          <div className="section-title">
            <h2>Cursos</h2>
          </div>
          <div className="row">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-md-5"
                    style={{
                      margin: "8px 10px 7px",
                      height: "450px",
                      borderRadius: "18px 18px 18px",
                      background: "#E8E2E1",
                    }}
                  >
                    {" "}
                    <br></br>
                    {/* <i className={d.icon}></i> */}
                    <iframe
                      Width="100%"
                      height="65%"
                      src={d.enlace}
                      title={d.title}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                    <br></br>
                  </div>
                ))
              : "Loading..."}
          </div>
          <div>
            <h3>Podes encontrar más videos en:</h3>
          </div>
          <div>
            <a
              href="https://www.youtube.com/@padrewalter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>
                <img
                  src={youtubeLogo}
                  alt="Logo de YouTube"
                  style={{ width: "70px", height: "30px" }}
                />
                <span style={{ marginLeft: "10px" }}></span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
    </div>
  );
};
