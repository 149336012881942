import React from "react";
import { useState, useEffect } from "react";
import { Navigation } from "../components/navigation";
import LogIn from "../components/admin/usuarios2";
//import { ApiCrud } from "../components/admin/api_crud";
//import BlogData from "../data/blogdata.json";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";

import "../App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Adm_in = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (

    <div>
      <Navigation />
      <br></br>
      <br></br>
      <br></br>
      <br></br><br></br>
      <br></br><br></br>
      <br></br>


      <div style={{
        position: "center",
        border: "3px solid black",
        margin: "20px 5% 5% 5%"
      }}><LogIn data={landingPageData.Login} /></div>
      <br></br>
     

    </div>
  );
};

export default Adm_in;