import React from "react";
import "../form.css";

export const Form = ({ onSubmit }) => {

  return (


    <form onSubmit={onSubmit}>
      <br></br>
        <br></br>
        <br></br>
      <h3>Abundancia</h3>
            <div className="form-group">
        <p>El pasto sabe algo que nosotros olvidamos. Conoce la abundancia. El universo, las piedras, las plantas, las galaxias y todos los animales cantan esta abundancia. ¿Pero el hombre? Vive al lado de este manantial divino del que todo brota y por orgullo, no quiere volver a la abundancia, su verdadero hogar.
La verde hierva descansa mientras crece. Sin esfuerzo, dejando que el viento la doble, sin una lista de todo lo que debería hacer, en silencio y alegría… simplemente crece.
Como un rio inagotable lleno de oxígeno que da vida, así, en este instante, esta abundancia nos invita a tomar de la mano al niño que hay en nosotros y jugar.
Esta página está naciendo con el deseo de que los que sintamos el llamado a la abundancia construyamos un faro, sabiendo que la noche es inevitable pero la belleza siempre triunfa.
No sé que saldrá, no tengo idea. Pero siento que el llamado a la felicidad es real y cercano. Que la cruz es solo parte de la resurrección y que el cielo comienza ahora..</p>
<p><br></br><br></br></p>
      </div>
    </form>
  );
};
export default Form;
