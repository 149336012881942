import React, { useState } from "react";
import LibAferras from "./Modals/LibroAferras/ContainerReg";
import LibAbundancia from "./Modals/LibroAbundancia/ContainerReg";
import LibSupieras from "./Modals/LibroSupieras/ContainerReg";
import Button from "react-bootstrap/Button";

export const Libros = (props) => {
  const [btn] = useState([]);

  const ModalL = () => {
    if (btn === "libAbundancia") {
      const LibAbundancia = (event) => {
        event.preventDefault(event);
      };
    }
    if (btn === "libAferras") {
      const LibAferras = (event) => {
        event.preventDefault(event);
      };
    }
    if (btn === "libSupieras") {
      const LibSupieras = (event) => {
        event.preventDefault(event);
      };
    }
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div id="libros" className="text-center">
      <div className="librosback">
        <div className="container">
          <div className="section-title">
            <h2>Mis libros</h2>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <img
                class="img-fluid" height="100%" width="80%" alt=""
                src="https://padrewaltermedina.com.ar/blogdata/img/Porqueformatos.jpg"
              />
            </div>
            <div class="col-md" style={{ alignItems:"justify" }}>

                <img
                  class="img-fluid" alt=""
                  src="https://www.padrewaltermedina.com.ar/blogdata/img/Argentina.jpg"
                  style={{ width: "30px", height: "30px" }}
                />
                &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.cuspide.com/Libro/9788409426874/Por+Que+Te+Aferras+A+Lo+Que+Te+Hace+Da%c3%b1o"
                  class="btn btn-warning"
                  role="button"
                >
                  Argentina
                </a>
                
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img
                  class="img-fluid" alt=""
                  src="https://www.padrewaltermedina.com.ar/blogdata/img/mexico.jpg"
                  style={{ width: "30px", height: "30px" }}
                />
                &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://delmedio-mx.quares.es/apex/quares/r/landingweb/detalle-producto?p2_id=32995&session=8220510646173"
                  class="btn btn-warning"
                  role="button"
                >
                  Mexico
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img
                  class="img-fluid" alt=""
                  src="https://www.padrewaltermedina.com.ar/blogdata/img/Spain.jpg"
                  style={{ width: "30px", height: "30px" }}
                />
                &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://delmedio.quares.es/apex/quares/r/landingweb/detalle-producto?p2_id=32995&session=11810735024046"
                  class="btn btn-warning"
                  role="button"
                >
                  España
                </a>
              
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    <div class="contenedorbook">
                      <img
                        src={d.smallImageB}
                        alt=""
                        style={{
                          position: "absolute",
                          width: "205px",
                          margin: "10px 5px",
                        }}
                      />
                      <img
                        class="top"
                        src={d.smallImage}
                        alt=""
                        style={{
                          position: "absolute",
                          width: "205px",
                          margin: "10px 5px",
                        }}
                      />
                    </div>
                    <div className="service-desc">
                      <p></p>
                      <h3>{d.name}</h3>
                      <p className="text-justificado">{d.text}</p>
                      <p hidden={d.enlace === "libSupieras" ? false : true}>
                        <LibSupieras
                          triggerRegistros="Leer mas..."
                          onSubmit={ModalL}
                          id={d.enlace}
                        />
                      </p>
                      <p hidden={d.enlace === "libAferras" ? false : true}>
                        <LibAferras
                          triggerRegistros="Leer mas..."
                          onSubmit={ModalL}
                          id={d.enlace}
                        />
                      </p>
                      <p hidden={d.enlace === "libAbundancia" ? false : true}>
                        <LibAbundancia
                          triggerRegistros="Leer mas..."
                          onSubmit={ModalL}
                          id={d.enlace}
                        />
                      </p>
                    </div>
                    <Button
                      variant="warning"
                      onClick={() => openInNewTab(d.sale)}
                    >
                      Comprar
                    </Button>{" "}
                  </div>
                ))
              : "loading"}
          </div>
        </div>
        <br></br>
        <br></br>
      </div>
    </div>
  );
};

/*
[15:47, 9/2/2023] Gaby Viñabal (walter Medina): Este nuevo libro ya esta disponible en España/Argentina/ México
[15:47, 9/2/2023] Gaby Viñabal (walter Medina): https://delmedio.quares.es/apex/quares/r/landingweb/detalle-producto?p2_id=32995&session=11810735024046
[15:47, 9/2/2023] Gaby Viñabal (walter Medina): Link de España 🇪🇸
[15:47, 9/2/2023] Gaby Viñabal (walter Medina): https://www.cuspide.com/Libro/9788409426874/Por+Que+Te+Aferras+A+Lo+Que+Te+Hace+Da%c3%b1o
[15:48, 9/2/2023] Gaby Viñabal (walter Medina): Link Argentina 🇦🇷
[15:48, 9/2/2023] Gaby Viñabal (walter Medina): https://delmedio-mx.quares.es/apex/quares/r/landingweb/detalle-producto?p2_id=32995&session=8220510646173
[15:49, 9/2/2023] Gaby Viñabal (walter Medina): Link México🇲🇽


*/
