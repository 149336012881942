import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navigation from "./components/navigation";
import Home from "./pages/Home";
//import Blog from "./pages/Blog";
import Blog from "./components/blog/blog";
import BlogPost from "./components/blog/BlogPost";
import AdmIn from "./pages/Admin";
import Usuarios2 from './components/admin/usuarios2';
import AdmiDash from './components/admin/adminDash';
//import CrudPost from "./components/admin/CrudPost";
//import Post from "./components/containers/Post";
//import BlogIA from "./components/containers/BlogIA";

import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


function App() {

  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path='/' element={<Home />} />
        {/* <Route path="/Blog" exact element={<Posts />} /> */}
        <Route path="/Admin" exact element={<AdmIn />} />
        <Route path="/Admin/adminDash" exact element={<AdmiDash />} />
        <Route path="/Usuarios2" exact element={<Usuarios2 />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogPost />} /> 
      </Routes>
    </Router>
  );
}

export default App;
