import React from "react";
import "../form.css";

export const Form = ({ onSubmit }) => {

  return (
    <form onSubmit={onSubmit}>
      <h3>Abundancia</h3>
      <div className="form-group">
        <p>Introduccion:
          "A veces nos encontramos con el amor de Dios, en medio de una brisa suave, pero también nos pasa que estamos tan distraídos de la brisa suave, que solo descubrimos la necesidad de su amor cuando llega la tormenta. Porque la vida con sus golpes tarde o temprano nos muestra lo chiquitos que somos.
          El orgullo siempre nos conduce al miedo y nos hace sentir inquietos, amenazados constantemente, pero nuestro yo verdadero es como un niño, es libre. El orgullo nos miente que somos lo que no somos. Cuando engaña a alguien lo hace sentir por momentos que el mundo será suyo, que tiene algo especial que lo separa del resto de los hombres. Pero luego acontecen situaciones que hacen emerger desde el fondo la conciencia, su pequeñez e impotencia, su debilidad e incapacidad, su no saber ni poder manejar con éxito la vida. Entonces al sentirse temeroso esconde este malestar, como quien niega la realidad de su enfermedad y esta se transforma en angustia y ansiedad, una angustia que está oculta en su alma y que ni siquiera puede ponerle nombre. Así mucha gente vive angustiada y triste porque no aceptan ser pequeñas.
          Las crisis[…]”</p>
        <p>
          La sabiduría de la oveja
          "A veces vivimos lejos de nuestra verdad. Porque no es fácil aprender que nuestra nada, es nuestra grandeza. Nuestra pequeñez, el baluarte más fuerte.
          Nuestra pobreza, debilidad, fracasos y pecados, esconden una bienaventuranza porque nos van disponiendo más dócilmente a dejar que Dios sea Dios a confiar en su amor.
          Esta es la sabiduría de la oveja que sabe que no puede sola y se deja apacentar. Pero como nos cuesta aceptar nuestros límites humanos, nuestro lugar en la vida. Muchas veces buscamos hacer lo que no nos corresponde, buscamos una grandeza que no es propia, dejamos de ser niños, porque no confiamos en que de verdad Dios hará algo por nosotros. Tantas veces nos han fallado que creemos que Dios también nos fallará. Entonces nos concentramos en lo que nosotros hacemos. Pero:
          “Nadie puede salvarse a sí mismo, ni pagar a Dios un rescate” (salmo 49).
          Aunque no neguemos que solo Dios puede salvarnos, es una aceptación intelectual. No queremos sabernos débiles e impotentes. No es fácil aceptar que no podemos salvarnos a nosotros mismos. Sin darnos cuenta, preferimos hacer las cosas por nosotros mismos, conquistar la vida.
          Cuando somos el[…]”
        </p>
        <p>Vacio
          "A veces vivimos fuera del eje de la realidad porque nos cuesta aceptar la vida como es. La vida siempre tiene una cuota de insatisfacción, que puede ser mayor o menor según el caso, pero que siempre está. Algo de lo que a menudo escapamos es a sentir nuestro vacío y soledad. Santa Madre Teresa de Calcuta, veía este vacío como una manera de recibir plenamente a Dios. Decía:
          “Querido colaborador de Cristo... le has dicho sí a Jesús y el te ha tomado la palabra. La palabra de Dios se hizo Jesús, el pobre. Y es por eso que experimentas ese terrible vacío. Dios no puede llenar lo que está lleno. Solo puede llenar lo vacío –la profunda pobreza– Y tu sí es el principio de ser y quedarte vacío. Sinceramente no se trata de cuanto tenemos para dar, si no de cuán vacío estamos, para así recibir plenamente en nuestras vidas, y dejar que él viva en nosotros.”
          Dios quiere llenar el vacío, grande o pequeño que hay en nuestra vida. Pero la manera de llenarlo es contraria a lo que buscamos. Se trata de darnos, no de buscar que nos den. Esa es[…]”</p>
        <p>Experimentar
          "Lo que necesitamos día a día, más que saber una linda idea es descubrir un hecho concreto, una acción, un obrar que es real. Y cuando lo contemplemos podremos decir, el hecho más real de nuestra vida: el Amor de Dios.
          “Todo me ha sido dado por mi Padre” (cf. Lc.10,21-24).
          Así decía Jesús, porque lo experimentaba día a día. Y nos quiere revelar esa buena noticia: “...nadie conoce al Padre sino el Hijo y aquel a quien el Hijo se la quiera revelar”
          Esa revelación está cerca de nosotros porque Dios actúa siempre, sutil, delicado, paciente, concreto. Y lo podemos conocer al ver como él actúa, lo que él obra, lo que él hace en nuestra vida.
          Muchos discuten quién es Dios, cómo es, dónde está; pero si contemplamos su obrar lo descubriremos más allá de los conceptos. Veremos que Dios es nuestro salvador.
          Los judíos, basaron su fe en un hecho salvífico: eran esclavos de los egipcios y Dios abrió el mar para que ellos pasen y así los salvó. Dios no era una idea, era quien había obrado con poder, quien había irrumpido en su historia concreta. Si un niño judío[…]”</p>
        <p>Felicitar/nos
          "Otra actitud importante que hoy queremos desarrollar, es cambiar la culpa malsana por la felicitación.
          La mayoría de las personas acusan y culpan demasiado. En cualquier error o situación que consideran mala, reaccionan juzgándola y luego acusando. Ven los errores, a veces, en todo los que le rodean. Todo lo pueden mejorar, todo lo juzgan.
          Desde que nace un niño se lo reta miles de veces. Una de las primeras palabras que entiende es el “¡No!”. Muchos padres creen que para educarlo correctamente hay que marcarle sus errores. Y si bien no decimos que no hay que ver los errores, si creemos que la fuerza que transforma a alguien es felic“15: Soltar
          En las dos primeras partes del libro hablamos de aceptar y creer que todo va a salir bien por que el Amor de Dios sigue allí firme, cantando nuestro propio canto. Son una forma de ver la realidad, ver la vida y recibirla con los brazos abiertos. Una constatación de lo que es, de lo que acontece a nuestro alrededor. Ahora queremos plantearnos una acción: confiar.
          Vimos, aceptamos, creímos, y entonces ahora nos toca dar el salto. Saltar al amor de Dios que hace tiempo nos espera para que conozcamos que es posible vivir en sus brazos. Salto que nos es imposible, es paradójico, no es algo que podamos hacer, generalmente nos atrapan nuestras inquietudes. Pero hay alguien que está más interesado que nosotros en que demos ese salto. Como un papá o una mamá que se sienten orgullosos a ver que sus hijos se parecen a ellos, así Dios Padre quiere realizar en nosotros el misterio de la entrega alegre y la donación confiada que él mismo vive el mismo en su ser trinitario.
          Este salto, lo damos soltando. Confiar es aprender a soltar. Soltar es dejar de prestar tanta atención, observación, evaluación a algo que[…]”
          Aplaudir la genialidad del otro, reconocer su luz es mucho más importante que señalar un error. Pero muchos, hacen todo lo contrario.
          Al felicitar al otro, le estamos mostrando como en un espejo, quien es en realidad. Si esto se lo muestran los padres, el niño crece con una conciencia de sí mismo saludable. Pero si en ese espejo se le muestra todo el tiempo sus errores crecerá con una conciencia falsa de quien es, creerá que no es bueno[…]”</p>
        <p>Soltar
          "En las dos primeras partes del libro hablamos de aceptar y creer que todo va a salir bien por que el Amor de Dios sigue allí firme, cantando nuestro propio canto. Son una forma de ver la realidad, ver la vida y recibirla con los brazos abiertos. Una constatación de lo que es, de lo que acontece a nuestro alrededor. Ahora queremos plantearnos una acción: confiar.
          Vimos, aceptamos, creímos, y entonces ahora nos toca dar el salto. Saltar al amor de Dios que hace tiempo nos espera para que conozcamos que es posible vivir en sus brazos. Salto que nos es imposible, es paradójico, no es algo que podamos hacer, generalmente nos atrapan nuestras inquietudes. Pero hay alguien que está más interesado que nosotros en que demos ese salto. Como un papá o una mamá que se sienten orgullosos a ver que sus hijos se parecen a ellos, así Dios Padre quiere realizar en nosotros el misterio de la entrega alegre y la donación confiada que él mismo vive el mismo en su ser trinitario.
          Este salto, lo damos soltando. Confiar es aprender a soltar. Soltar es dejar de prestar tanta atención, observación, evaluación a algo que[…]”</p>
        <p>Abbá
          "A veces nos encontramos con el amor de Dios, en medio de una brisa suave, pero también nos pasa que estamos tan distraídos de la brisa suave, que solo descubrimos la necesidad de su amor cuando llega la tormenta. Porque la vida con sus golpes tarde o temprano nos muestra lo chiquitos que somos.
          El orgullo siempre nos conduce al miedo y nos hace sentir inquietos, amenazados constantemente, pero nuestro yo verdadero es como un niño, es libre. El orgullo nos miente que somos lo que no somos. Cuando engaña a alguien lo hace sentir por momentos que el mundo será suyo, que tiene algo especial que lo separa del resto de los hombres. Pero luego acontecen situaciones que hacen emerger desde el fondo la conciencia, su pequeñez e impotencia, su debilidad e incapacidad, su no saber ni poder manejar con éxito la vida. Entonces al sentirse temeroso esconde este malestar, como quien niega la realidad de su enfermedad y esta se transforma en angustia y ansiedad, una angustia que está oculta en su alma y que ni siquiera puede ponerle nombre. Así mucha gente vive angustiada y triste porque no aceptan ser pequeñas
          Las crisis[…]”</p>
      </div>
    </form>
  );
};
export default Form;
