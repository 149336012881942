import React from "react";
import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className="portfolioback">
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className='container'>
          <div className='section-title'>
            <h2>Fotos</h2>
          </div>
        <div className='row'>
          <div className='portfolio-items' >
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
                  <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
      </div>
      <br></br><br></br>
      </div>
    </div>
  )
}
