import React, { useState } from "react";
import youtubeLogo from "../data/img/logoAlfarcito.png";

export const About = (props) => {
  const [showMessage, setShowMessage] = useState(false);

  const redirectToAlfarcito = () => {
    setShowMessage(true);
    setTimeout(() => {
      window.open("https://www.fundacionalfarcito.org.ar/", "_blank");
      setShowMessage(false);
    }, 3000); // Redirigir a YouTube después de 3 segundos
  };

  return (
    <div id="about" className="aboutback">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Padre Walter R. Medina</h2>
              <p className="text-justificado">
                {props.data ? props.data.paragraph : "loading..."}
              </p>
              <p></p>
            </div>
          </div>
          <div
            className="col-xs-12 col-md-4"
            style={{
              margin: "5px 5px 5px 5px",
              borderRadius: "10px 10px 10px 10px",
              background: "#ffffff",
              paddingBottom: "10px",
            }}
          >
            {" "}
            <img
              src="img/pwm.jpg"
              className="img-responsive"
              alt=""
              style={{ borderRadius: "18px 18px 18px 18px" }}
            />{" "}
          </div>
        </div>
      </div>
      <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        {showMessage && <div>Usted será redirigido a Fundacion Alfarcito...</div>}
        <button onClick={redirectToAlfarcito}>
          <img
            src={youtubeLogo}
            alt="Fundacion Alfarcito"
            style={{ width: "100px", height: "30px" }}
          />
          <span style={{ marginLeft: "1px" }}>Visite Fundacion Alfarcito</span>
        </button>
      </div>
    </div>
  );
};
export default About;
