import React from 'react'
import { Link } from "react-router-dom";

export const Navigation = () => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>P.W.Medina</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          
          <a className='navbar-brand page-scroll' href='/#page-top'>
          P. Walter R. Medina</a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='/#Cursos' className='page-scroll'>
                Cursos
              </a>
            </li>
            <li>             
              <Link to="/Blog">Mi Blog</Link>
            </li>
            <li>
              <a href='/#about' className='page-scroll'>
                Sobre Mi
              </a>
            </li>
            <li>
              <a href='/#libros' className='page-scroll'>
                Libros
              </a>
            </li>
            <li>
              <a href='/#portfolio' className='page-scroll'>
                Videos
              </a>
            </li>
            <li>
              <a href='/#testimonials' className='page-scroll'>
                Mensajes
              </a>
            </li>
            {/* <li>
              <a href='#team' className='page-scroll'>
                Team
              </a>
            </li> */}
            <li>
              <a href='/#contact' className='page-scroll'>
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navigation;