import React, { useState, useEffect } from "react";
import EditForm from "./cruds/EditForm";
import "./admin.css";
import { useNavigate } from "react-router-dom";

const Admin = () => {
  const [data, setData] = useState([]);

  const [selectedTable, setSelectedTable] = useState("blog");
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isAddingRow, setIsAddingRow] = useState(false); // Variable para mostrar botones en filas

  const fetchData = (table) => {
    fetch(
      `https://www.padrewaltermedina.com.ar/php/admintabla${table}.php?action=fetch${table}`
    )
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleTableChange = (tableName) => {
    setIsEditing(false);
    setIsAddingRow(false); // Mostrar botones en las filas
    setSelectedTable(tableName);
    fetchData(tableName);
  };

  useEffect(() => {
    fetchData(selectedTable);
  }, [selectedTable]);

  const renderTableHeaders = () => {
    // Define table headers based on the selected table      style={{backgroundColor:"#007bff", color:"white", textAlign: "center"}}
    switch (selectedTable) {
      case "usuarios":
        return (
          <tr>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Id_usuario
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Usuario
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Password
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Rol
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Mail
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              MAC
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Fecha
            </th>
            <th
              style={{
                backgroundColor: "#409cff",
                color: "white",
                textAlign: "center",
              }}
            >
              Opciones
            </th>
            {/* Adcd more header columns */}
          </tr>
        );
      case "blog":
        return (
          <tr>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              idblog
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              imagen
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              titulo
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              subtitulo
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              post
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              post1
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              enlace
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              fechayhora
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              Opciones
            </th>

            {/* Add more header columns */}
          </tr>
        );
      case "comentarios":
        return (
          <tr>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              idcomentarios
            </th>{" "}
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              blogid
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              nombre
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              comentario
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              ip
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              fechayhora
            </th>
            <th
              style={{
                backgroundColor: "#FFAC33",
                color: "white",
                textAlign: "center",
              }}
            >
              Opciones
            </th>
            {/* Add more header columns */}
          </tr>
        );
      default:
        return null;
    }
  };

  const renderTableRows = () => {
    // Render table rows based on data and selected table
    return data.map((row, index) => (
      <tr key={index}>
        {/* Map data properties to table cells based on selected table */}
        {Object.keys(row).map((key) => (
          <td
            key={key}
            style={{
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {row[key]}
          </td>
        ))}
        <td>
          {isAddingRow ? (
            ///<button onClick={() => handleSave(row)}>Guardar</button>
            //<button>la nada misma</button>
            <p></p>
          ) : (
            <>
              <button onClick={() => handleEdit(row)}>Editar</button>
              <button onClick={() => handleDelete(row)}>Eliminar</button>
              <button onClick={() => handleAdd(row)}>Agregar</button>
            </>
          )}
        </td>
      </tr>
    ));
  };

  const handleAdd = (data) => {
    setIsEditing(true);
    setIsAddingRow(true); // Mostrar botones en las filas
    setIsAdding(true); // Indicar que se agrega un nuevo registro en el formulario
    setEditData(data); // Reinicia los datos del formulario
  };

  const handleEdit = (data) => {
    setIsEditing(true);
    setEditData(data);
  };

  const handleUpdate = (updatedData) => {
    // Actualizar los datos en el estado
    const updatedArray = data.map((item) =>
      item.Id_usuario === updatedData.Id_usuario ? updatedData : item
    );
    setData(updatedArray);
    setIsEditing(false);
    setEditData(null);
  };

  const handleDelete = (data) => {
    const confirmed = window.confirm(
      `¿Estás seguro de que deseas eliminar el registro?`
    );
    if (confirmed) {
      fetch(
        `https://www.padrewaltermedina.com.ar/php/admintabla${selectedTable}.php?action=delete${selectedTable}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: data.Id_usuario || data.idblog || data.idcomentario,
          }),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            const updatedArray = data.filter(
              (item) =>
                item.Id_usuario !== data.Id_usuario ||
                item.idblog !== data.idblog ||
                item.idcomentario !== data.idcomentario
            );
            setData(updatedArray);
          }
        })
        .catch((error) => console.error("Error deleting data:", error));
      fetchData(selectedTable);
    }
  };

  let navigate = useNavigate();
  function Salir() {
    console.log("dentro del handle go()");
    //historyObj.push("../AdminDash");
    navigate("../Admin");
  }

  function irBlog() {
    const url = "/Blog"; // La URL a la que deseas dirigir al usuario
    const win = window.open(url, "_blank");
    if (win) {
      // Abrió la nueva ventana con éxito
      win.focus();
    } else {
      // Bloqueador de ventanas emergentes puede haber evitado la apertura
      // Aquí puedes mostrar un mensaje al usuario
      alert("La apertura de ventanas emergentes está bloqueada. Habilítala para abrir la página en una nueva pestaña.");
    }
  }
  // function irBlog() {
  //   console.log("dentro del handle go() para ir a blog");
  //   //historyObj.push("../AdminDash");
  //   navigate("../Blog");
  // }
  return (
    <div>
      <br />
      <br />
      <div class="container">
        <div class="left-div">
          <div className="section-title">
            <h2 style={{ textAlign: "center" }}>
              <i className="fas fa-cogs" style={{ fontSize: "34px" }}></i>
              DASHBOARD ADMINISTRACION
            </h2>
          </div>
        </div>
        <br />
        <div class="right-div">
          <button className="exit-button" onClick={Salir}>
            Salir
            <i class="fas fa-sign-out-alt"></i>
          </button>
        </div>
        <div class="right-div">
          <button className="section-button" onClick={irBlog}>
            Ir al Blog {" "  } 
            <i class="fas fa-blog" style={{paddingLeft:"10px"}}></i>
          </button>
        </div>
      </div>
      <div className="section-buttons">
        <button
          className="section-button"
          onClick={() => handleTableChange("blog")}
        >
          <i className="fas fa-blog"></i> Blog
        </button>

        <button
          className="section-button"
          onClick={() => handleTableChange("comentarios")}
        >
          <i className="fas fa-comment"></i> Comentarios
        </button>
        <button
          className="section-button"
          onClick={() => handleTableChange("usuarios")}
        >
          <i className="fas fa-users"></i> Usuarios
        </button>
      </div>

      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "85%",
        }}
      >
        <table>
          <thead>{renderTableHeaders()}</thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>
      {isEditing && (
        <EditForm
          data={editData}
          table={selectedTable}
          onClose={() => {
            setIsEditing(false);
            setIsAdding(false);
            setIsAddingRow(false); // Mostrar botones en las filas
            fetchData(selectedTable); // Actualiza los datos en el componente padre
          }}
          onUpdate={isAdding ? handleUpdate : null}
          isAdding={isAdding}
          onCancel={() => setIsEditing(false)} // Función de cancelación para actualizar los datos
        />
      )}
      {/* {isEditing && (
        <EditForm
          data={editData}
          table={selectedTable}
          onClose={() => {
            setIsEditing(false);
            setIsAdding(false); // Asegurarse de que isAdding se restablezca
          }}
          onUpdate={isAdding ? handleUpdate : null}
          isAdding={isAdding}
        />
      )} */}

      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default Admin;
